// Import dependencies
import React from "react"
import ReactBnbGallery from "react-bnb-gallery"

// Import components
import Layout from "../components/layouts/layout"
import SEO from "../components/seo/seo"
import Header from "../components/header/header"
import {
  PageContainer,
  Margins,
  Gap150,
  ParagraphText,
  SubTitleText,
  TitleText,
} from "../components/styled/UILibrary"
import TextSection from "../components/general/textSection"
import TitleSection from "../components/general/titleSection"
import TextBox from "../components/general/textBox"
import ContactCallToAction from "../components/general/contactAction"

// Bootstrap components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

// Import styles
import "bootstrap/dist/css/bootstrap.css"
import "./styles/history.css"
import "./styles/home.css"
import "./styles/products.css"
import "./styles/scrollbar.css"
import "../components/general/bgbutton.css"

// Import product images
import productSpecs from "../assets/images/product-specification.png"
import Cross from "../assets/icons/cross.svg"

// Import images
const generalGen = require("../assets/images/general-gen.jpg")
const general = require("../assets/images/general.jpg")
const sanitary = require("../assets/images/sanitary.jpg")
const decorative = require("../assets/images/decorative.jpg")

export default class ProductsPage extends React.Component {
  constructor() {
    super(...arguments)
    this.state = { galleryOpened: false }
    this.toggleGallery = this.toggleGallery.bind(this)
  }

  toggleGallery() {
    this.setState(prevState => ({
      galleryOpened: !prevState.galleryOpened,
    }))
  }

  componentDidMount() {
    // Hello for developers
    console.clear()
    console.log(
      "%cDesigned & developed by Dmitry Poliyivets – https://www.linkedin.com/in/dpoliyivets/",
      "background: #0000FF; color: #ffffff; font-size: 12px; padding: 25px; font-family: 'Montserrat', sans-serif; line-height: 22px"
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="Products" keywords={[`gatsby`, `application`, `react`]} />

        <div className="history__container">
          {!this.state.galleryOpened ? <Header /> : null}
          {/* Page contents */}
          <PageContainer>
            <Margins>
              <Gap150 />

              <TextSection
                smallTitle="OUR PRODUCTS"
                title="Synthetic glass"
                paragraph="Acrylic offers a safer alternative to glass that has the clarity of all but the most specialized coated glass. Acrylic can reach up to 98% transparency. We offer a selection of different colors, dimensions, surfaces, and thicknesses that can fulfill the most sophisticated specifications."
              />
              <ParagraphText
                minSize={18}
                maxSize={20}
                style={{ marginTop: 40 }}
              >
                We don't just manufacture plexiglass, we pioneered its
                fabrication in Egypt. Spiroplastic has been in business since
                the 1970s. We're a legacy company of high values and
                dependability.
              </ParagraphText>
              <ParagraphText
                minSize={18}
                maxSize={20}
                style={{ marginTop: 40 }}
              >
                Leading the acrylics industry for half a century, our factories
                can fulfill large sheet and custom orders. Our acrylic is
                premium quality, lightweight, and strong.
              </ParagraphText>

              <Gap150 name="benefits" />

              <SubTitleText minSize={14} maxSize={16}>
                BENEFITS OF PLEXIGLASS
              </SubTitleText>
              <Container
                fluid
                style={{ margin: 0, padding: 0 }}
                className="home__equal"
              >
                <Row className="ml-0 mr-0">
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Resists weathering and sharp blows
                    </TitleText>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Flexible compared to glass
                    </TitleText>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Less breakable than glass
                    </TitleText>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Withstands sunlight
                    </TitleText>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Resistant to chemicals and industry fumes
                    </TitleText>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Can transmit or filter ultraviolet light
                    </TitleText>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Clean easily
                    </TitleText>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pr-0 pl-0 products__benefits-box"
                  >
                    <TitleText
                      minSize={18}
                      maxSize={26}
                      style={{ width: "80%" }}
                      center
                      lineheight="130%"
                    >
                      Corrosion Resistant
                    </TitleText>
                  </Col>
                </Row>
              </Container>

              <Gap150 name="uses" />

              <TitleSection smallTitle="USES" title="Uses of acrylic sheets" />
              <Container
                fluid
                style={{ margin: 0, padding: 0 }}
                className="home__equal"
              >
                <Row>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="General"
                      paragraph="Our general purpose acrylics are for various uses and comes in different colors. It possesses excellent optical properties, withstands impact, features superior chemical corrosion resistance, and possesses superior fabrication characteristics."
                      hoverImage={generalGen}
                    />
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="Advertisement"
                      paragraph={
                        "Public advertisements such as billboards, bus stop signage, and poster covers need to be easily visible, safe from breakage, and easy to clean. Our advertisement grade acrylic stands up to weather and graffiti. It maintains it's clarity even in high UV environments like the Egyptian desert. We've kept Cairo ads visible and shiny for decades. We can do the same for ads in your community."
                      }
                      hoverImage={general}
                    />
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="Sanitary"
                      paragraph="Our sanitary products are those that have specifications that meet worldwide specifications on sanitary usage. This acrylic can be used in kitchens, bathrooms, and laboratories."
                      hoverImage={sanitary}
                    />
                  </Col>
                  <Col
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="home__column-padding"
                  >
                    <TextBox
                      title="Decorative"
                      paragraph="Decorative plexiglass comes in just about any color or style imaginable. Whatever you can imagine, we can provide in acrylic plates that are durable and will keep their color for years."
                      hoverImage={decorative}
                    />
                  </Col>
                </Row>
              </Container>

              <Gap150 name="product-gallery" />

              <div className="products__product-galery-container">
                <div className="products__product-galery-call-to-action">
                  <div className="products__small-line" />
                  <ParagraphText minSize={18} maxSize={20}>
                    Click here to view our product specifications.
                  </ParagraphText>
                </div>
                <div
                  className="bgbutton__container bgbutton__blue"
                  onClick={this.toggleGallery}
                >
                  <ParagraphText minSize={14} maxSize={16} white>
                    VIEW PRODUCT SPECIFICATIONS
                  </ParagraphText>
                </div>
              </div>

              <Gap150 />

              <ContactCallToAction />
            </Margins>
          </PageContainer>
        </div>

        {this.state.galleryOpened && (
          <div
            style={{
              width: `100vw`,
              height: `100vh`,
              position: `fixed`,
              top: 0,
              left: 0,
              backgroundColor: `white`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              zIndex: 50,
            }}
          >
            <div
              onClick={this.toggleGallery}
              style={{
                height: 50,
                width: 50,
                backgroundColor: `white`,
                position: `absolute`,
                right: 7,
                top: 0,
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                cursor: `pointer`,
              }}
            >
              <Cross style={{ height: 25 }} />
            </div>
            <img
              src={productSpecs}
              alt="Product Specifications"
              style={{ height: `90vh`, width: `90vw`, objectFit: `contain` }}
            />
          </div>
        )}
      </Layout>
    )
  }
}
